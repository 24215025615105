* {
    box-sizing: border-box;
}

.App {
    text-align: center;
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
}

.App-header {
    transition: 0.5s;
    opacity: 1;
    font-size: 7vh;
    font-family: 'Major Mono Display', monospace;
    margin-top: 2%;
}

.App-header.loading {
    margin-top: 20%;
    font-size: 10vh;
}

.games {
    padding-top: 2%;
    transition: 0.5s;
    opacity: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.game {
    position: relative;
    flex: 1 1 auto;
    width: 20%;
    max-width: 500px;
    min-width: 250px;
    margin: 20px;
    background: #f2f2f2;
    border: 1px solid #ccc;
}

.game .preview {
    position: relative;
    overflow: hidden;
}

.game:hover .preview img,
.game:hover .preview h3 {
    opacity: 0;
}

.game .preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
    opacity: 1;
}

.game .preview h3 {
    transition: 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0.4));
    margin: 0;
    padding: 0;
    padding-bottom: 35px;
    padding-top: 4vh;
    color: white;
    opacity: 1;
}

.game video {
    width: 100%;
    display: block;
}


.game .info {
    position: relative;
    min-height: 60px;
    padding: 10px;
}

.game .rating-cont {
    margin-top: -40px;
}

.rating.pill {
    font-weight: bold;
    font-size: 2vh;
    margin-bottom: 10px;
}

.rating .rating-num {
    line-height: 31px;
    float: left;
}

.play-button {
    background: #000;
    font-family: 'Major Mono Display', monospace;
    color: #fff;
    padding: 10px 30px;
    display: inline-block;
    text-decoration: none;
    font-size: 3vh;
    transition: 0.3s;
}

.play-button:hover {
    background: #fff;
    color: #000;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}


.viewing {
    margin-top: 1vh;
    opacity: 0;
    transition: 2s;
    background: #f2f2f2;
    border: 1px solid #ccc;
    max-height: 0%;
}

.viewing.show {
    opacity: 1;
    max-height: 200%;
}

.viewing .info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.viewing video {
    max-width: 500px;
}

.viewing .info .left,
.viewing .info .right {
    padding: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 200px;
    max-width: 600px;
}


.pill {
    background: #fff;
    font-family: 'Major Mono Display', monospace;
    padding: 10px 15px;
    color: #000;
    border-radius: 25px;
    display: inline-block;
}


.overlay {
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    z-index: 20;
}

.overlay.show {
    opacity: 1;
    pointer-events: initial;
}

.review-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.review {
    background: #fff;
    width: 40%;
    position: relative;
    padding: 1vh;
    pointer-events: initial;
    cursor: initial;
}

.review .close {
    font-family: 'Major Mono Display', monospace;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2vh;
    padding: 0.5vh;
    cursor: pointer;
}

.info-button-cont {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    width: 20%;
    height: 20%;
    background: radial-gradient(82.07px at 96.05% 6.25%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.243765) 43.65%, rgba(0, 0, 0, 0) 100%);
    padding: 10px;
}

.info-button-cont .info-button {
    height: 3vh;
    float: right;
    cursor: pointer;
}


.star-rating {
    font-size: 4vh;
}

.star-rating .star {
    opacity: 1;
    cursor: pointer;
}

.star-rating .star:hover~.star {
    opacity: 0.4;
}

.header-links {
    transition-duration: 0.3s;
    transition-delay: 2s;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: right;
}

.header-links a {
    margin: 20px;
}

.link {
    font-family: 'Major Mono Display', monospace;
    color: #000;
    transition: 0.3s;
}

.page-description {
    transition-delay: 1s;
    transition-duration: 0.8s;
    font-family: 'Major Mono Display', monospace;
    font-size: 1.7vh;
    padding-top: 1vh;
}

footer {
    font-family: 'Major Mono Display', monospace;
    transition: 0.3s;
    padding: 5vh;
    opacity: 0.5;
}

footer:hover {
    opacity: 1;
}

@media (max-width:650px) {
    .header-links {
        display: none;
    }
}

@media (max-height:600px) {
    .rating.pill {
        font-size: 4vh;
    }

    .info-button-cont .info-button {
        height: 4vw;
    }

    .play-button {
        font-size: 6vh;
    }

    .star-rating {
        font-size: 8vh;
    }

    .page-description {
        padding-top: 2vh;
        font-size: 4vh;
    }
}